import React, { useEffect, useRef } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import event1 from '../../assets/images/event-1.png';
import event2 from '../../assets/images/event-2.png';
import event3 from '../../assets/images/event-3.png';
import event4 from '../../assets/images/event-4.png';
import eventVideo from '../../assets/images/eventVideo-5.mp4';


const Event = () => {
    const navigate = useNavigate();
    const sectionRef = useRef();

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    })

    return (
        <>
            <div ref={sectionRef}>
                <Header fileName={'events'} />
                <div className="events">
                    <div className="container">
                        <button onClick={() => navigate('/')} className="back">Back to Home</button>
                        <div className="title">
                            <h5>Media/Events</h5>
                        </div>
                        <div className="row">
                            <div className='col-6'>
                                <img src='../../assets/images/event-1.png' alt='event-1 image' />
                            </div>
                            <div className='col-6'>
                                <img src='../../assets/images/event-3.png' alt='event-3 image' />
                            </div>
                            <div className='col-6'>
                                <img src='../../assets/images/event-2.png' alt='event-2 image' />
                            </div>
                            <div className='col-6'>
                                <img src='../../assets/images/event-4.png' alt='event-4 image' />
                            </div>
                        </div>
                        <div>
                            <video width="320" height="240" controls>
                                <source src='../../assets/images/eventVideo-5.mp4' type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Event