import React, { useEffect, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { get_All_Offers_Api } from '../../api'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Offer = () => {
    const navigate = useNavigate();
    const [data, setdata] = useState([]);
    const [Singledata, setSingledata] = useState([]);
    const [LoaderTrue, setLoaderTrue] = useState(false);
    const GetData = () => {
        var config = {
            method: 'get',
            url: get_All_Offers_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                    setLoaderTrue(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
    }, [])
    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            <Header fileName={'offer'} />
            <div className="offer">
                <div className="container">
                    <button onClick={() => navigate('/')} className="back">Back to Home</button>
                    <div className="title">
                        <h5>Great offer For you</h5>
                    </div>
                    <div className="row">
                        {data.map((item, index) => {
                            return (
                                <div className="col-md-6 col-12" key={item._id} onClick={() => setSingledata(item)}>
                                    <div className="image" data-bs-toggle="offcanvas" data-bs-target="#OfferDetailsModal" style={{cursor: 'pointer', height: '636px'}}>
                                        <img src={item.image} className="w-100 h-100 object-fit-cover" style={{ borderRadius: "15px" }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />

            <div className="offer offcanvas offcanvas-end" id="OfferDetailsModal" tabIndex="" role="dialog" aria-labelledby="OfferDetailsModalLabel" aria-hidden="true">
                <div className="offcanvas-body">
                    <div className="topic">
                        <h2>Offers</h2>
                        <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="../../assets/images/close.png" /></button>
                    </div>
                    <div className="code">
                        <h5>{Singledata.code}</h5>
                        <a className="copy" onClick={()=>{
                            navigator.clipboard.writeText(Singledata.code);
                            toast.success('Code Copied');
                        }}>COPY</a>
                    </div>
                    <h4>Get 40% off</h4>
                    <p>Use code {Singledata.code} & get {Singledata.title}.</p>
                    <h6>Terms and Conditions</h6>
                    <p>{Singledata.terms}</p>
                </div>
            </div>
        </>
    )
}

export default Offer