import React, { useEffect, useRef } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'


const Faqs = () => {
    const navigate = useNavigate();
    const sectionRef = useRef();

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    })

    return (
        <>
            <div ref={sectionRef}>
                <Header fileName={'faqs'} />
                <div className="faqs">
                    <div className="container">
                        <button onClick={() => navigate('/')} className="back">Back to Home</button>
                        <div className="title">
                            <h5>Wok On Fire FAQs by Mr Mischief Food Co.</h5>
                        </div>
                        <div id="accordion">
                            <div className="box">
                                <div className="header-bar " id='collapseOne'>
                                    <a className="title collapsed" data-bs-toggle="collapse" type="button" data-bs-target="#headingOne" aria-expanded="true" aria-controls="headingOne">
                                        What's on the Menu?
                                    </a>
                                </div>
                                <div id="headingOne" className="body collapse" aria-labelledby="collapseOne" data-bs-parent="#accordion">
                                    <p>We dish up delectable Indo-Chinese cuisine, a vibrant fusion of Indian spices and Chinese cooking techniques. </p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="header-bar " id='collapseTwo'>
                                    <a className="title collapsed" data-bs-toggle="collapse" type="button" data-bs-target="#headingTwo" aria-expanded="true" aria-controls="headingTwo">
                                        Healthy and Flavorful?
                                    </a>
                                </div>
                                <div id="headingTwo" className="body collapse" aria-labelledby="collapseTwo" data-bs-parent="#accordion">
                                    <p>Absolutely! We craft delicious meals using fresh, high-quality ingredients, proving healthy doesn't have to be boring. </p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="header-bar" id='collapseThree'>
                                    <a className="title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#headingThree" aria-expanded="false" aria-controls="headingThree">
                                        Vegetarian or Vegan?
                                    </a>
                                </div>
                                <div id="headingThree" className="body collapse" aria-labelledby="collapseThree" data-parent="#accordion">
                                    <p>No problem! We have a wide variety of options to satisfy your dietary needs.</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="header-bar">
                                    <a className="title" data-bs-toggle="collapse" data-bs-target="#headingFour" aria-expanded="true" aria-controls="headingFour">
                                        Ready to Order?
                                    </a>
                                </div>
                                <div id="headingFour" className="body collapse" data-parent="#accordion">
                                    <p>
                                        Order Online: Enjoy the convenience of online ordering through Zomato and Swiggy.<br />
                                        Delivery Zone: We deliver within a 6 KM radius of our restaurant.<br />
                                        Minimum Order for Delivery: Orders must be ₹ 600 or more to qualify for delivery.<br />
                                        Delivery Timeframe: Get your food fix fast! Deliveries typically take 45 to 60 minutes.<br />
                                        Payment Options:Pay Your Way: We accept all major debit and credit cards, as well as online payments and QR code scans.</p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="header-bar " id='collapseOne'>
                                    <a className="title" data-bs-toggle="collapse" data-bs-target="#headingFifth" aria-expanded="true" aria-controls="headingFifth">
                                        Looking for More?
                                    </a>
                                </div>
                                <div id="headingFifth" className="body collapse" data-parent="#accordion">
                                    <p>
                                        Happy Hour: Craving deals? Join us every Wednesday for a flat 50% off on dine-in and take-away orders (terms and conditions apply).
                                    </p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="header-bar " id='collapseOne'>
                                    <a className="title" data-bs-toggle="collapse" data-bs-target="#headingSix" aria-expanded="true" aria-controls="headingSix">
                                        The Masterminds Behind the Wok: Who is Mr Mischief Food Co.?
                                    </a>
                                </div>
                                <div id="headingSix" className="body collapse" data-parent="#accordion">
                                    <p>
                                        We are a passionate team dedicated to creating innovative and delicious Indo-Chinese cuisine at Wok On Fire.
                                    </p>
                                </div>
                            </div>
                            <div className="box">
                                <div className="header-bar " id='collapseOne'>
                                    <a className="title" data-bs-toggle="collapse" data-bs-target="#headingSeven" aria-expanded="true" aria-controls="headingSeven">
                                        Franchise Inquiries?
                                    </a>
                                </div>
                                <div id="headingSeven" className="body collapse" data-parent="#accordion">
                                    <p>
                                        Contact us at +91 9512822522 or +91 9925183333 or email franchise@wokonfire.in
                                        Contact Us:

                                        Call us at 7203066666.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Faqs