const port = 4000;
// const url = `http://localhost:${port}/wokonfire/api/v1/`;
// const url = `http://192.168.1.14:4000/wokonfire/api/v1/`;
const url = `https://api.wokonfire.in/wokonfire/api/v1/`;
const user = `${url}user/`;


// User apis
export const Get_Nearest_Outlet = `${user}getNearestOutlet`;
export const Register_User_Api = `${user}register`;
export const Login_User_Api = `${user}login`;
export const Outlet_Search_Api = `${user}outlet/search`;
export const User_Verify_Api = `${user}login_verify`;
export const Get_Product_Data_Api = `${user}product`;
export const Get_SuperCategory_Data_Api = `${user}superCategory`;
export const Get_Category_Data_Api = `${user}category`;
export const Get_Wishlist_Data_Api = `${user}get_wishlist`;
export const Get_SuperCategory_By_Product_Data_Api = `${user}productBySuperCategory/`;
export const Get_SuperCategory_By_Product_Or_Category_Data_Api = `${user}getProductOrCategoryBySuperCategory/`;
export const Get_Category_By_Product_Data_Api = `${user}productByCategory/`;
export const Add_Cart_Api = `${user}add_to_cart`;
export const Add_Wishlist_Api = `${user}add_to_wishlist/`;
export const Search_Products_Api = `${user}searchProducts/`;
export const Filter_Products_Api = `${user}filterProducts`;
export const Get_Cart_Api = `${user}get_cart`;
export const Clear_Cart_Api = `${user}clear_cart`;
export const Get_Login_User_Api = `${user}user_profile`;
export const Update_Login_User_Api = `${user}user_profile/update`;
export const Change_CartProduct_Que_Api = `${user}changeProductsQuentity`;
export const Update_Cart_Addons_Api = `${user}updateCartAddons`;
export const Delete_Product_Cart_Api = `${user}deleteProductFromCart`;
export const Order_With_Cod_Api = `${user}OrderWithCOD`;
export const Order_With_Upi_Api = `${user}OrderWithOnlinePayment`;
export const Upi_Request_Handler_Api = `${user}transaction/ccavRequestHandler`;
export const Upi_Response_Handler_Api = `${user}transaction/ccavResponseHandler`;
export const Get_Order_Api = `${user}order/getAllOrder`;
export const Clear_Wishlist_Api = `${user}clear_wishlist`;
export const Add_Address_Api = `${user}user_add_address`;
export const Update_Address_Api = `${user}user_update_address/`;
export const Delete_Address_Api = `${user}user_delete_address/`;
export const Last_Order_Api = `${user}order/latestOrder/`;
export const Get_Slider_Api = `${user}getAllSlider`;
export const Get_ContentData_Api = `${user}GetContentData`;
export const Get_MenuSuperCatagoryData_Api = `${user}GetMenuSuperCatagoryData`;
export const Get_AllCityOutlet_Api = `${user}GetAllCityOutlet`;
export const Change_Delivery_Type_Api = `${user}changeDeliveryType`;
export const Add_Money_Wallet_Api = `${user}add_to_wallet`;
export const Last_Payment_Api = `${user}latestPayment/`;
export const Wallet_Payment_Api = `${user}walletPayments/`;
export const Update_PickUp_Outlet = `${user}updatePickUpLocation`;
export const get_All_Offers_Api = `${user}getAllOffers`;
export const Check_OfferCode_Api = `${user}offer/apply`;
export const Check_Outlet_Active_Api = `${user}outlet/checkActive`;
export const Log_Out_Api = `${user}logout`;