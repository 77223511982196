// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/style.css'
// import './App.css';
// import './index.css';
import HomePage from './Component/Pages/HomePage';
import ProductPage from './Component/Pages/Product';
import Cart from './Component/Pages/Cart';
import OrderNow from './Component/Pages/OrderNow';
import Profile from './Component/Pages/Profile';
import Faqs from './Component/Pages/Faqs';
import Disclaimer from './Component/Pages/Disclaimer';
import Offer from './Component/Pages/Offer';
import { Navigate, Route, Routes } from 'react-router-dom';
import SuccessPayment from './Component/Pages/SuccessPayment';
import PaymentFailed from './Component/Pages/PaymentFailed';
import WalletSuccessPayment from './Component/Pages/WalletSuccessPayment';
import WalletPaymentFailed from './Component/Pages/WalletPaymentFailed';
import Policy from './Component/Pages/Policy';
import TermsCondition from './Component/Pages/TermsCondition';
import About from './Component/Pages/About';
import Event from './Component/Pages/Event';

const RequireAuth = ({children,redirectto}) => {
  const token = localStorage.getItem('authToken');
  if(token && token !== ''){
    return children
  } else {
    return <Navigate to={redirectto} />
  }
}

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/product" element={<ProductPage />} />
      <Route exact path="/product/:filter_id" element={<ProductPage />} />
      <Route exact path={'/cart'} element={
          <RequireAuth redirectto={'/'} > <Cart /> </RequireAuth>
      }/>
      <Route exact path={'/ordernow'} element={
          <RequireAuth redirectto={'/'} > <OrderNow /> </RequireAuth>
      }/>
      <Route exact path={'/profile'} element={
          <RequireAuth redirectto={'/'} > <Profile /> </RequireAuth>
      }/>
      <Route exact path={'/profile/:params'} element={
          <RequireAuth redirectto={'/'} > <Profile /> </RequireAuth>
      }/>
      <Route exact path={'/successPayment'} element={
          <RequireAuth redirectto={'/'} > <SuccessPayment /> </RequireAuth>
      }/>
      <Route exact path={'/paymentFailed'} element={
          <RequireAuth redirectto={'/'} > <PaymentFailed /> </RequireAuth>
      }/>
      <Route exact path={'/WalletsuccessPayment'} element={
          <RequireAuth redirectto={'/'} > <WalletSuccessPayment /> </RequireAuth>
      }/>
      <Route exact path={'/WalletpaymentFailed'} element={
          <RequireAuth redirectto={'/'} > <WalletPaymentFailed /> </RequireAuth>
      }/>
      {/* <Route exact path={'/payment'} element={
          <RequireAuth redirectto={'/'} > <Payment /> </RequireAuth>
      }/> */}
      <Route exact path="/faqs" element={<Faqs />} />
      <Route exact path="/events" element={<Event />} />
      <Route exact path="/disclaimer" element={<Disclaimer />} />
      <Route exact path="/offer" element={<Offer />} />
      <Route exact path="/policy" element={<Policy />} />
      <Route exact path="/terms&condition" element={<TermsCondition />} />
    </Routes>
  );
}

export default App;
